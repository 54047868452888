
.loginModal .modal-dialog{
    width:35% !important;
}
.loginModal .modal-dialog .modal-content .modal-header{
    display:flex;
    justify-content:center !important;
    padding-top: 50px;
}

.loginModal .modal-dialog .modal-content .modal-body{
    display:flex;
    justify-content:center !important;
}
.loginModal .modal-dialog .modal-content .modal-footer{
    border-top: none !important;
    justify-content: center;
}

@media (max-width: 767px) {
    .loginModal .modal-dialog{
        width:95% !important;
    }

}